@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');


/* Proxima Nova - Light */
@font-face {
  font-display: swap;
  font-family: "Proxima Nova";
  src: url(./fonts/proxima-nova-reg.woff2) format("woff2"),
    url(./fonts/proxima-nova-reg.woff) format("woff"),
    url(./fonts/proxima-nova-reg.otf) format("opentype");
  font-style: normal;
  font-weight: 200;
}

/* Proxima Nova - Regular */
@font-face {
  font-display: swap;
  font-family: "Proxima Nova";
  src: url(./fonts/proxima-nova-reg.woff2) format("woff2"),
    url(./fonts/proxima-nova-reg.woff) format("woff"),
    url(./fonts/proxima-nova-reg.otf) format("opentype");
  font-style: normal;
  font-weight: 400;
}

/* Proxima Nova - Bold */
@font-face {
  font-display: swap;
  font-family: "Proxima Nova";
  src: url(./fonts/proxima-nova-bold.woff2) format("woff2"),
    url(./fonts/proxima-nova-bold.woff) format("woff"),
    url(./fonts/proxima-nova-bold.otf) format("opentype");
  font-style: normal;
  font-weight: 600;
}

/* Futura - Book */
@font-face {
  font-display: swap;
  font-family: "Futura";
  src: url(./fonts/futura-book.woff2) format("woff2"),
    url(./fonts/futura-book.woff) format("woff"),
    url(./fonts/futura-book.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

/* Futura - Bold */
@font-face {
  font-display: swap;
  font-family: "Futura";
  src: url(./fonts/futura-bold.woff2) format("woff2"),
    url(./fonts/futura-bold.woff) format("woff"),
    url(./fonts/futura-bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 600;
}

$darkgreen: #274548;
$lightGreen:#9FE2DD;
$darkergreen: rgb(18, 33, 34);
$lightblue: #9FE2DD;
$beige: #FBF6EE;
$orange: #EDC034;
$darkerOrange: #d8a200;
$darkOrangeTransparent:rgba(216,162,0,0.7);
$fontSize: 18px;
$headerFont: 'Futura',sans-serif;
$bodyFont: 'Proxima Nova', sans-serif;
// $headerFont: 'roboto mono','futura-pt-bold',sans-serif;
// $bodyFont: 'VT323',monospace;
$breakpoint-tablet: 768px;
$breakpoint-phone: 600px;

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

html,body,#root {
    height: 100%;
}

@mixin flexh {
   display: flex;
   flex-flow: row wrap;
}

@mixin flexhc {
  @include flexh;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
}

@mixin flexv {
  display: flex;
  flex-flow: column wrap;
}

@mixin flexvc {
  @include flexv;
  align-content: center;
  justify-content: center;
}

body {
  color: $darkgreen;
  font-size: $fontSize;
  font-family: $bodyFont;
  margin: 0;
  padding: 0;
}

#page-container {
  .loading {text-align: center;margin-top: 20px;}

  #signIn, #landing,#forget,#signup,#launchpad,#universe,#classes,#new-class,#clazz {
    background-color:$darkgreen;
    color: #fff;
    h1 {
      font-size: 6rem;
      color: #fff;
      text-shadow: rgb(53, 173, 185) 2px 1px 0px;  
      text-align: center;
    }
  }
}

img {
  max-width: 100%;
}

h1,h2,h3,h4,h5,h6 {
    font-family: $headerFont;
    padding: 0;
    margin: 0;
}

#landing h1 {
  margin-top: -5rem;
  margin-bottom: 0.5rem;
}

p {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.6rem;
}

a {
    text-decoration: none;
    outline: none;
    color: $darkgreen;
} 

button { 
    font-size: $fontSize;
    font-family: $bodyFont;
}

.center {
  text-align: center;
}

.title {
  font-size: 1.4rem;
  margin-top: -30px;
  font-family: $headerFont;
}

img.pixel{
  // image-rendering:pixelated;
}

#page-container {
    display: grid;
    height: 100%;
    grid-template-rows: 70px 1fr;
    position: relative;
  .loading {
    text-align: center;
  }
}

#header {
    background-color: #fff;
    width: 100%;
    border-bottom: 5px solid $lightGreen;
    display: flex;
    .logo {
      @include flexvc;
      margin: 0 40px;
      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }
    .nav {
      @include flexh;
      line-height: 70px;
      width:100%;
      height: 70px;
      justify-content: flex-end;
      .signIn {
        color: #fff;
        text-align: center;
        border-radius: 8px;
        transition: background-color 2s;
        background-color: $orange;
        padding: 10px 20px;

        &:hover {
          background-color: $darkerOrange;
        }
      }
     > a {
        margin: 0 20px;
        &:hover {
          border-top: 4px solid $lightGreen;
 
        }
        &:not(.logo) {
          text-align:right;
        }
      }
      .hideMenu {
        display:none;
      }   
      .showMenu {
        @include flexv;
        text-align: left;
        line-height: 1rem;
        background-color: $lightblue;
        z-index: 9999;
        position: absolute;
        top: 70px;
        margin: 0 40px 0 0;
        button {
          border-radius: 0;
          padding: 5px 20px;
          width: 100%;
          background-color: $darkerOrange;
        }
        a {
          padding: 10px 40px;
          text-align:left;
          z-index: 1;
        }
        a:hover {
          background-color: $orange;
        }
      }
      .dropdown {
        cursor: pointer;
        text-align: right;
        height: 70px;
        z-index: 99;
        margin: 0 40px;
        #menu {
          @include flexh;
          justify-content: flex-end;
          padding: 0 5px;
          &.highlight {
            border-top: 4px solid $lightGreen;
          }
          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
            margin-right: 10px;
            margin-top:  15px;
          }
          &:after {
          width: 0; 
          height: 0; 
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid $darkgreen;
          content: "";
          cursor: pointer;
          margin: 35px 0 0 5px;
          }
          &:hover {
            border-top: 4px solid $lightGreen;
          }
        }
      }
    }
}

button, .button {  
  border-radius: 4px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: column wrap;
  margin: 0 auto;
  border: 0;
  outline: 0;
  width: 100px;
  padding: 10px;
  grid-column: 10;
  background-color: $orange;
  cursor: pointer;
  transition: background-color 2s;
  a {
    color: #fff;
  }
  &:hover {
    background-color: $darkerOrange;
  }
}

button {
  background-color: $orange;

}

.main {
    a {
      font-weight: bold;
      color: $orange;
    }
    form {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    margin: 0 auto;
    input, button {
      display: block;
      margin: 10px auto;
      padding: 10px 0;
      font-family: $bodyFont;
      width: 250px;
      border: 0;
      outline: 0;
    }
    input {
      padding-left: 10px;
    }
    input[type=checkbox] {
      display: inline-block;
      width: auto;
      margin: 20px 10px;
    }
    label {
      color: $orange;
      display: inline-block;
  }  
    button {
      width: 260px;
    }
    p {
      color: $lightblue;
      background-color:black;
    }
  }
 
}

#landing, #signup, #signIn,#forget {
  background:url('/images/bg.png') no-repeat;
  background-size: cover;
  background-position: 10% 50%;
  #rocket {
    top: 50px;
  }
  .main {
    grid-column: span 12;
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    justify-content: center;
    color: #fff;
    height: 100%;
    .button {
      color: #fff;
    }
  } 
}

#signin {

  .roles {
    display:flex;
    flex-flow: row wrap;
  }
}

.launch {
  background-color: $orange;
  color: #fff;
}

#profile, #clazz, #untutorial, #new-project, #jetfuel, #question, #progress-reviews {
  .main {
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 100%;
    background-color: #fff;
    color: $darkgreen;
  .sidebar {
    color: #fff;
    background-color:$beige;
    // padding: 60px 30px;
    background-color: $darkgreen;
    word-wrap: break-word;
      padding: 60px 30px;
      > div {
        margin-bottom: 40px;
      
    }
    button {
      padding: 0 6px;
      width: auto;
      margin: 4px auto;
    }
    h1 {
      font-size: 1.4rem;
      color: $darkgreen;
      text-shadow: none;
    }
    p {
      margin: 0;
      padding: 0;

    }
    span {
      font-size: 0.8rem;
    }
    .container {
      position: relative;
      .description {
        margin-top: 20px;
      }
      p {
        margin-top: 20px;
      }
    }
    select {
      margin-right: 5px;
    }
    .field {
      line-height: 1.8rem;
      &.draft > div {
        background-color: red;
      }
      &.approved  {
        div {
        background-color:#00b700;
        }
      }
      &.level {
        font-size: 1.6rem;
      }
      button {
          font-size: 1rem;
          padding: 2px 6px;
          background-color: $lightGreen;
          color: $darkgreen;
          display: inline;
      }
      &.display-name {
        font-size: 2rem;
        margin-top: 10px;
        font-family: $headerFont;
        line-height: 2rem;
        position: relative;
        background-color: $darkgreen;
        color: #fff;
        text-align: center; 
        .edit {
          top: 0;
          right: 30px;
        }
      }
    }
    .content {
      border-radius: 4px;
      .field {
        position: relative;
        text-align: center;
        padding: 0 30px;
        h1 {
          font-size: 2rem;
          color: $darkgreen;
        }
        img {
          position: absolute;
          right: 0;
          top: 0;
        }
    }
      .container {
        margin: 20px 0;
      }
    }
  }
  .main-content {
    color: $darkgreen;
    position: relative;
    padding: 60px;
    position: relative;
    .approve {
      text-align: center;
      margin-top: 20px; 
      div {
        color: $orange;
        position: relative; 
        display: inline;
        padding-right: 20px;
      }
    }
    .container {
      .field {
        position: relative;
        margin: 0;
        background-color: $beige;
        padding: 0 40px;
        border-radius: 8px; 
        font-weight: bold;
        .edit {
          right: 2px;
          top: 5px;
        }
      }
    }
    .content {
      > div { 
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .field {
        position: 2px solid red;

        img {
          width: 25px;
          height: auto;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      h4 {
        p {
          margin: 0;
          display: inline;
          padding: 0;
        }
      }
      }

    } 
  }
  .quill {
    color: $darkgreen;
    // max-width: 525px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
  }
 }
}

#launchpad, #universe,#clazz {
  .filter {
    div {
      width: 100%;
    }
    button {
      margin-bottom: 5px;  
      display:block;
      width: 100%;
      position: relative;
      background-color: #fff;   
      transition: normal;  
    }
    .f {
      background-color: #eeefef;
      &:after {
        content:"x";
        position: absolute;
        right: 10px;
      }
    }
  }
  .main {
    color: #fff;
    display: grid;
    display: grid;
    grid-template-columns: 1fr 5fr;
    .main-content {
      background-color: $darkgreen;
      color: #fff;
      .search {
        padding: 10px;
        width: 50%;
        display:block;
        margin: 0 auto;
        border: 3px solid $orange;
        outline: 0;
        border-radius: 4px;
        margin: 40px auto;
      }
    .items {
     @include flexhc;
     h1 {
       margin: 20px 0;
     }

    > a {
      background-color: #fff;
      border-radius: 8px;
      color: $darkgreen;
      width: 28%;
      margin: 2%;
      position: relative;
      box-shadow: 0 1px 3px 0 rgba(0,0,0,.12), 0 1px 2px 0 rgba(0,0,0,.24);
      // flex-grow: 1;
      img {
         width: 100%;
         height: 130px;
         object-fit: cover;
         border-top-left-radius: 8px;
         border-top-right-radius: 8px;
         box-shadow: 0 1px 3px 0 rgba(0,0,0,.12), 0 1px 2px 0 rgba(0,0,0,.24)

      }
      > div {
        padding: 40px 20px;
        font-size: 1rem;
        font-family: $bodyFont;
        font-weight: 300;
        min-height: 200px;
        h2 {
          margin-bottom: 20px;
        }
        p {
         line-height: 1.6rem;
        }
        button {
          width: auto;
          background-color: $darkgreen;
          color: #fff;
          position: absolute;
          bottom: 40px;
          left: 0;
          right: 0;
        }
      }
    }
  }
    }
    .sidebar {
      background-color: #fff;
      border-right: 5px solid $orange;
      width: 250px;
      h2 {
        color: $darkgreen;
        margin-bottom: 20px;
      }
      button {
        transition: background-color 2s;
        &:hover {
          background-color:#eeefef;
        }
      }
    }
  }
}

#classes {
h1 {
  margin-top: 20px;
}
.button {
  display: block;
  margin: 0 auto;
  color: $darkgreen;
  text-align: center;
}
.main {
 @include flexhc;  
a {
  color: $darkergreen;
  width: 200px;
  height: 300px;
  background-color: white;
  margin: 40px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  h4 {
    margin-top: 30px;
  }
  button {
    background-color: $darkgreen;
    color: #fff;
    bottom: 10px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  img {
    max-width:100%;
    height: 100px;
    margin: 0 auto;
    display: block;
    object-fit: contain;
  }
 }
 }
}

#clazz {
  .main {
  .main-content {
      h1 {
        text-align: center;
      }
      .items {
        > a {
          transition: 2s;
          margin: 40px auto;
          width: 300px;
          height: 300px;
          text-align: center;
          font-family: $headerFont;
          text-transform: capitalize;
          background:url(/static/media/logo.5a255a59.png) no-repeat center center;
          background-size: contain;
          background-color: $lightGreen;
          color: #fff;
          border-radius: 8px;
          position: relative;
          padding-bottom: 0;

         span {
           position: absolute;
           bottom: 10px;
           color: $darkgreen;
           left: 0;
           right: 0;
           margin: 0 auto;
          &:hover {
            background-color:#61bbb5;
          }
        }
        }
      }
      .console {
        .showConsole {
          display: block;
          margin: 40px auto;
        }
        .hideConsole {
          display: none;
        }
      }
    }
  .sidebar {
    width: 250px;
    padding: 20px;
    color: $darkgreen;
    text-align: center;
    .avatar {
      border:0;
      width: 200px;
      height: 100px;
      padding: 0;
      img {
        position: static;
        width: 100%;
        height: 100%;
      }
    }
   }
  }
 }
// #clazz {
//   .main {
//   .main-content {
//     >div {
//       margin: 40px auto;
//       h3 {
//         margin-bottom: 20px;
//       }
//     }
//   }
//   .sidebar {
//     max-width: 300px;
//     padding: 20px;;
//     .avatar {
//       border:0;
//       width: 100%;
//       padding: 0;
//     }

//   }
// }
// }

#page-container { 
 #launchpad {
  .main {
    h1 {
      width: 100%;
      font-size: 3rem;  
    }
  }
}
}

#untutorial,#new-project  {
  .blur {
   opacity:0.5;
  }
  .thumbnail {
    height: auto;
    .add {
      text-align:left;
      margin: 20px 0 0;
      font-weight: bold;  

    }
    .upload {
    border:0;
    height: 20px;
    margin: 20px 0 0;
    }

   .replace {
     position:absolute;
     left:0;
     width:100%;
     height:100%;
     cursor:pointer;

   }
   .change {
     position:absolute;
     bottom:-30px;
     margin: 0 auto;
     left:0;
     right:0;
   }

  }
  .workOnProject {
    h3 {
      text-align: center;
      border-top: 2px solid $darkgreen;
      background-color:$orange;
      padding: 10px;
    }
  }
  .iframe-off {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
    border-radius: 50%;
    padding: 10px;
  }
   .iframe-on {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    .popup {
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.8);
      border-radius: 4px;
      position: relative;
      max-width: 600px;
      a {
          position: absolute;
        cursor: pointer;
          top: 20px;
          font-family: $headerFont;
          font-weight: bold;
          color: black;
        &.back {
          left: 20px;
        }
        &.close {
          right: 20px;
        }
      }
      > div {
        padding: 20px;
        text-align:center;
      }
      iframe {
        margin-top: 20px;
      }
      button {
        background-color: $darkgreen;
        color: #fff;
        padding: 4px 20px;
        width: auto;
        margin: 10px auto;
      }
      .toggle-iframe {
        display: none;
      }
      &:before {
        content: "Try It Out!";
        display: block;
        background-color: $orange;
        padding: 10px;
        font-weight: bold;
        text-align: center;
        font-size: 2rem;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
    iframe {
      display: block;
      margin: 0 auto;
    }

  }
  .main {
    background-color: #f7f7f7; 
    .addDelete {
      @include flexhc;
    }
    .del {
      margin-top: 20px;
      width:auto; 
      background-color: $darkgreen;
      color:#fff;
    }
    .publish {
      width: auto;
      margin-top: 30px;
    }
    grid-template-columns: 2fr 1fr;
    border-top: 2px solid $darkgreen;
    padding: 0 5%;
   .scratch {
    grid-column: span 2;
    img {
    width: 130px;
  }

  }
  .sidebar {
      background-color:transparent;
      color: $darkgreen; 
      margin-top: 20px;
      margin-right: 20px;
      padding-top: 30px;
      .tags {
        z-index:9;
      }
      .filter-categories {
        a {
          display: block;
          margin: 20px 0;
        } 
      }
      .quill {
        button {
          // background-color: #fff;
        }
      }
      .titleStatus {
        .title {
          margin-top: 0;
          font-family: $bodyFont;
          font-weight: bold;
          p {
            margin: 0;
            padding: 0;
            line-height: 2rem;
            font-size: 2rem;
            display: inline;
            font-family: $headerFont;
          }
        }
      }
      .container {
        border-bottom: 1px solid #ccc;
        padding-bottom:5px;
        margin-bottom: 20px;
        @include flexv;
        .field {
          button {
            background-color: $darkgreen;
            color: #fff;
            display: block;
            margin-left:0;
          }
          .quill {
            button {
              background-color: transparent;
              color: $darkergreen;
            }
          }
          input {
            border: 0;
            padding: 0;
            border-bottom: 2px solid $darkergreen;
            font-size: 1rem;
            width: 100%;
            &:focus {
              outline: 0;
            }
          }
        }
        .checkout {
          margin-left: 0;
          margin-top: 10px;
          padding: 10px 30px;
        }
      }
   }
  .main-content {  
    .status {
      > div  {
        margin-left: 2px;
      }
    }
    padding-top: 30px;
    text-align: center;
    h3 {
      font-size: 2rem;
    }
    .thumbnail {
      width: auto;
      margin: 40px 0;
      img {
        width: auto;
      }
    }
    .url {
      margin: 0 0 40px;
      font-size: 1.4rem;
      h2 {
        margin-top: 20px;
      }
      input {
        padding: 8px;
        border:4px solid $orange;
        border-radius: 4px;
        width: 50%;
        font-size: 1.5rem;
        &:focus {
          outline: 0;
        }
      }
      button {
        background-color: $darkgreen;
        color: #fff;
        width: auto;
        padding: 2px 6px;
        margin: 10px auto;
        font-size: 1rem;
      }
    }
    .steps {
      &:hover {
      // max-height: 800px;
      // overflow-y: auto;
      }
    .step{
      text-align: left;
      background-color: #fff;
      position: relative;
      width: 533px;
      margin: 20px auto 60px;
      border-bottom: 1px solid #ccc;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
      padding: 0 20px 60px;
      border-radius: 10px;
      .step-title {
        font-size: 2rem;
      }
      .no-button {
        button, .editor-button {
        display: none;
        }
      }
      .step-title {
        @include flexh;     
        font-weight: 700;
        font-size: 2rem;
        padding-top: 10px;
        word-break: break-all;
        font-family: $bodyFont;
          div{
            img{
              width:60px;
            }
          }
        
      .header {
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        > div:not(.quill) {
          font-size: 2rem;
          margin: 0;
        }
        input {
          border: 0;
          border-bottom: 2px solid $darkgreen;
          margin-left: 10px;
          &:focus {
            outline: 0;
          }
        }
        button {
          width: auto;
          padding: 2px 4px;
          margin-left: 10px;

        }
        .editor-button {
          width: auto;
          margin: 0;
          display: flex;
          margin-left: 10px;
        }
       }
      }
      .step-content {
        margin-top: 20px;
       button {
         width: auto;
         display: block;
         margin: 0;
       }
       .done-button {
         position: absolute;
         top: 0;
         right: 0;
       }
      }
      .delete {
        position: absolute;
        bottom: 10px;
      }
      .comments {
        margin-top:10px;
        background-color:$lightGreen;
        padding:10px;
      }
      // button {
      //   position: absolute;
      //   width: auto;
      //   &:not(.delete) {
      //     top: 0;
      //   }
      // }
      // &.done {
      //   bottom: 0;
      // }
      // .editor {
      //     button {
      //     right: 0;
      //     }
      //     &.header {
      //       width: 100%;
      //       button {
      //         left: 0;
      //       }
      //     }
      // }
      .field {
        > div {
          font-size: 1.2rem;
          font-weight: 300;
          text-align: left;
          margin-bottom: 10px;
        }


          iframe {
            margin-top: 20px;
            display: block;
            margin: 20px auto;
          }
        }
      }
    }
  }
}
.sidebar {
  iframe {
    display:none;
  }
}
}

#untutorial {
  .thumbnail {
    width: 100%;
    margin: 0;
    margin-top: 40px;
    object-fit: cover;
    position: relative;
    img, label {
      width: 100%;
      height: 400px;
      margin: 0;
      border: 0;
      object-fit: cover;
    }
    &.hero {
      height: 272px;
      border: 0;
      margin-top: 0;
      top: 0;
      img,label {
        height: 272px;
      }
    }
  }
  .status {
    .approved {
      background-color:rgb(80, 221, 15);
      background:radial-gradient(circle,#0bec3c, #0f9c0f);
      width:15px;
      height:15px;
      border-radius:50%;
    }
    .todo {
      width:15px;
      height:15px;
      border-radius:50%;
      background-color:rgb(247, 48, 48);
      background:radial-gradient(circle, rgb(236, 99, 99), rgb(247, 48, 48),);
    }
    .pending {     
      background-color:$orange;
      background:radial-gradient(circle, #e9dd33,$orange);
      height:15px;
      width:15px;
      border-radius:50%;
    }
   > div:not(.field) {
    margin: 5px;
    &.red {
      background-color:rgb(247, 48, 48);
      background:radial-gradient(circle, rgb(236, 99, 99), rgb(247, 48, 48),);
    }
    &.blue {
      background-color:blue;
      background:radial-gradient(circle, rgb(17, 147, 233), rgb(4, 61, 136),);
    }
  }
  }
}

#new-project { 
  .main {
    @include flexhc;
    justify-content: center;
    margin: 0;
    padding: 0;
    border-top: 0;
    .main-content {
      .steps {
        .step {
        padding-bottom:0;
        }
      }
    }
    .toolbar {
      position: sticky;
      @include flexhc;
      width: 100%;
      height: 50px;
      top: 0;
      z-index: 99;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
      text-align: center;
      button {
        width: auto;
        margin: 5px 10px 0;
        color: #fff;
        background-color: $darkgreen;
        width: 15%;
        transition: 2s;
        &:hover {
          background-color: $darkergreen;
        }
      }
  }
  .sidebar { 
     padding-top: 30px;   
     width: 300px;
    .container {
      .thumbnail {
        height: auto;
        border:0;
        img {
          height: auto;
        }
        label {
          height: 50px;
        }
      }
      p {
        margin: 0;
      }
    > div {
      h4 {
        position: relative;
        text-align:left;
      }
      position: relative;
      margin-bottom: 10px;
    }
    .filter-categories {
     @include flexv;
     a {
       margin: 5px 0;
       cursor: pointer;
     }
    }
   }
  }
  .field {
        margin: 0;
        position:static;
        padding: 0;
        button {
          z-index: 2;
          top: 0;
          display: block;
          text-align: left;
          .quill {
        
          }
        }
  }
  .delete {
        bottom: 2px;
        margin: 0 auto;
        left: 0;
        right: 0;
  }
  }
 }
 
#signin, #forget, #signup, #signIn {
  background:url('/images/bg2.png') no-repeat;
  background-size: cover;
  background-position: 10% 20%;
  #rocket {
    top: -40px;
    z-index:0;
  }
}

#signup {
  labels {
    @include flexh;    
  }
} 

#resources {
  .toolbar {
  }
  .main {
    @include flexvc;
  }
  button {
    display: block;
    width: auto;
    margin: 20px auto;
  }
}
#rocket {
  background:url('/images/roket.png') no-repeat;
  position: absolute;
  z-index:99;
  width: 100px;
  height: 190px;
  background-size: contain;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#launchpad, #universe, #classes {
  color: $orange;
  a {
    display: inline-block;
    cursor: pointer;
    color: $orange;
  }
  .filter {
  display:flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 30px auto;
  max-width: 600px;
  div {
   text-align: center;
   margin:1rem;
  }
  }
  .filter-categories {
    text-align: center;
    margin-top: 20px;
    a {
      color: $darkgreen;
      background-color: $orange;
      padding: 5px;
      margin: 10px;
      display: inline;
      border-radius: 5px;
      &:after {
        content: "x";
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .container {
    width: 50%;
    position: relative;
    margin: 0 auto;
.field {

.edit {
  position: absolute;
  
  right: 0;
  top: 0;
  }
}
}
}

#profile {
 a {
    color: $darkgreen;
  }
  .status {
    > div {
      margin: 5px;
    }
  }
  .sidebar {
    > div {
      margin-bottom: 20px;
      cursor: pointer;
    }
    .selected {
      h2 {
        border-bottom: 5px solid $lightGreen;
        display: inline-block;
      }
    }
  }
  .main {
  .tab-labels {
    text-align: center;
    list-style: none;
    padding: 0;
    line-height: 35px;
    height: 37px;
    overflow: hidden;
    font-size: 12px;
    position: relative;
    div {
      border: 1px solid #AAA;
      background: #D1D1D1;
      background: linear-gradient(top, #ECECEC 50%, #D1D1D1 100%);
      display: inline-block;
      position:relative;
      z-index: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), inset 0 1px 0 #FFF;
      text-shadow: 0 1px #FFF;
      margin: 0 -5px;
      padding: 0 30px;
      &.selected {
        background: #FFF;
        color: #333;
        z-index: 2;
        border-top-color: #FFF;
        &:before {
          box-shadow: 2px 0px 0 #FFF;
        }
        &:after {
          box-shadow: -2px 0px 0 #FFF;
        }
      }
      &:before,&:after {
       border: 1px solid #AAA;
       position: absolute;
       top: -1px;
        width: 6px;
        height: 6px;
        content: "";
      }
      &:before {
        left: -7px;
        border-top-right-radius: 6px;
        border-width: 1px 1px 0px 0px;
        box-shadow: 2px 0px 0 #ECECEC;
      }
      &:after {
        right: -7px;
        border-top-left-radius: 6px;
        border-width: 1px 0px 0px 1px;
        box-shadow: -2px 0px 0 #ECECEC;
      }
    }        
    h3 {
      cursor: pointer;
    }
    &:before {
      position: absolute;
      content: "";
      width: 100%;
      top: 0;
      left: 0;
      border-top: 1px solid #AAA;
      z-index: 1;
    }
  }
  .tabs {
    .field {
      position: relative
    }

    .tab-content {
      .title {
        background-color: $lightGreen;
        text-align: center;
        border: 3px solid #7ae2da;
      }
      .instructions {
        grid-template-columns: repeat(4,1fr);
        text-align: center;
      }
      .fa {
        transition: 1s ease-in-out;
        &:hover {
          animation: spin 1s infinite;
        }
      }
      > a {
        padding: 10px;
        padding-top: 20px;
        grid-template-columns: 1fr 2fr 1fr;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
        display: grid;
        margin-bottom: 10px;
        transition: 1s;
        &:hover {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
        }
      }

      img {
        width: 25px;
      }
      
   }
   .progress,.projects, .untutorials {
    h3 {
      background-color: $lightGreen;
      padding: 0 5px;
      margin-top: 20px;
      grid-column: span 3;
      cursor: pointer;
      position: relative;
      &:after {
        content: '^';
        position: absolute;
        right: 10px;
        top: 5px;
      }
      &.down {
        &:after {
        transform: rotate(180deg);
        top: 0;
      }
      }
    }
  }
  .profile {
  .copy {
    display: flex;
    margin-bottom: 4px;
    i {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  textarea {
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
    width: 100%;
    font-family: $bodyFont;
    font-size: $fontSize;
  }
    button {
      margin: 5px 0;
      width: auto;
      font-size: 1rem;
      padding: 2px 4px;
    }
  h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2rem;
  }
 .avatar {
  margin-bottom: 40px;
 }
  > div {
    margin-bottom: 20px;
    word-wrap: break-word;  
  }
  }  
  .email {
    max-width: 960px;
    background-color: #fff;
    h1,h2,h3,h4,h5,h6,button,ul,li {
      color: initial;
      font-size: initial;
      text-shadow: initial;
    }
    #emailloader {
      display: block;
      > ul {
          list-style: none;
          margin: 0;
          word-wrap: break-word;
          li {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 100px 1fr;
          margin-bottom: 40px;
          border-bottom: 2px solid #ccc;
          padding-bottom: 20px;
          padding: 40px 10px;
          margin: 0;
          .email-date {
            text-align: right;
          }
          .email-body {
            grid-column: span 2;
            img {
              max-width: 100%;
            }
          }
        }
      }
    } 
   }
  }
 }
}

.status {
margin: 0 auto;
}

.progress {
  .status {
    margin: 0;
  }
}

#untutorial, #profile, #progress-reviews {
  .fa {
    font-size: 1.6rem;
  }
  .fa-code {
    background-color: $darkgreen;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  .progress {
  .pending, .approved {
    color:$orange;
    text-shadow: 0 0 3px $orange, 0 0 5px #e9dd33;
  }
}
  .complete {
    text-align: center;
  }
  .yellow {     
    color:$orange;
    text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF;
  }
  .green {
    color:rgb(80, 221, 15);
    text-shadow: 0 0 3px #046404, 0 0 5px #0bec3c;
  }
  .white {
    color:rgb(255, 255, 255);
    text-shadow: 0 0 3px $orange, 0 0 5px #dfb704;
  }

}

#profile {
  #projects, #untutorials {
  .status {
    justify-content: center;
  }
  }
}

#simulator {
  background-color: #fff;
  .coding-area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.thumbnail, .avatar {   
  position: relative;
  text-align: center;
  margin: 0 auto 40px;
  img {
    left: 0;
    right: 0;
    margin: 0 auto;
    object-fit: contain;
  }
  input[type="file"] {
   display: none;
  }
  .upload {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    // &:after {
    //   content: "Choose Image";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background-color: #000;
    //   color: #fff;
    // }
  }

}

.avatar {
  width: 300px;
  height: 300px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  img {
    width: 300px;
    height: 300px;
    position: absolute;
  }
  .upload {
  width: 300px;
  height: 300px;
  padding: 5px;
  }
}

.thumbnail {
  width: 200px;
  height: 150px;
  margin: 5px 0 20px;
  // width: 300px;
  // height: 150px;
  img {
    width: 200px;
    height: 150px;
    object-fit: cover;
  }
  .upload {
    bottom: 0;
    // width: 300px;
    // height: 150px;
    width: 200px;
    height: 150px;
    display: block;
    border:1px solid black;
  }
}

#new-class {
  .main-area {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    color: $darkgreen;
    padding: 20px;
    @include flexvc;
    border-radius: 8px;
    h4 {
      text-align: center;
    }
  .thumbnail {
    margin-top: 20px;
    .upload {
      border: 1px solid #ccc;
    }
  }  
 } 
 button {
   width: auto;
   margin: 20px auto 40px;
 }
}

#jetfuel, #question {
  .main {
  .sidebar {
    padding: 0px;
    h2 {
      font-size: 3rem;
    }
    button {
      margin-bottom: 20px;
      display: block;
    }
  }
  .main-content {
  h1 {
    color: $darkgreen;
  }
  input {
    display: block;
    margin: 20px 0;
  }
}
}
  // color: #fff;
  .main {
    .main-content {
      a {
      color:$darkgreen;
    }
    // max-width: 600px;
    // margin: 0 auto;
    > div {
      background-color: #fff;
      margin-bottom: 20px;
    }
    
  }
}
}

#jetfuel {
  .main-content {
    > div {
      > a {
        @include flexh;
      }
    }
  }
}

#progress-reviews {
  .sidebar {
    > div {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      margin: 40px 0 0;
      > div{
        margin: 10px 0;
        display: flex;
      }
      .search {
       width: 80%;
      padding: 10px 5px;
      }
    }
    ul {
      li {
        list-style: none;
        cursor: pointer;
        margin: 10px 0;
      }
    }

  }
  .main-content {
    h1 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 40px;
    }
    > div {
      margin-bottom: 20px;
    }
  .status {
    h4 {
      margin: 10px 0;
    }
  }
  .giveFeedback,.aside {
    display: flex;
    button,a {
      display: block;
      margin: 10px;
      width: auto;
    }
    a {
      margin-top:0;
    }
    textarea {
      width: 400px;
    }
  }
}
}