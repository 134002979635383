:root{
  --innerBG:rgba(48, 43, 36,0.8);
  --border:rgba(168, 168, 168,1);
  --insideWindowBG:rgba(82, 80, 78,0.95);
  --insideWindowBorder:rgba(56, 47, 38,1);
  --green:green;
  --red:red;
  --orange:orange;
}
#overlay {
  font-size:13px;
  position: fixed; 
  width: 70%; 
  height: 80%; 
  top: 10%;
  left: 15%;
  right: 15%;
  bottom: 10%;
  image-rendering: pixelated;
  background-image: url("/images/crt-background.png"); 
  background-position: center;
  background-size: cover;
  background-clip: content-box;
  border-top: 25px solid transparent;
  border-left: 30px solid transparent;
  border-bottom: 60px solid transparent;
  border-right: 30px solid transparent;
  border-image-source: url("/images/crt-border.png");
  border-image-slice: 4 5 8 5 fill;
  border-image-outset: 0;
    
  z-index: 2; 
  cursor: pointer; 
    /*box-shadow:
    -6px  0   0   0   var(--border),
    6px  0   0   0   var(--border),
    0  6px   0   0   var(--border),
    0  -6px   0   0   var(--border);*/

}
#topBar{
  display:block;
  width:100%;
  height:25px;
  background-color: var(--insideWindowBG); 
  box-shadow: 
    6px 0 0 0 var(--insideWindowBorder),
    -6px 0 0 0 var(--insideWindowBorder),
    0 6px 0 0 var(--insideWindowBorder),
    0 -6px 0 0 var(--insideWindowBorder);
}
#topBar h4{
    float: left;
    height: 90%;
    padding-top: 2px;
    padding-left: 5px;
}
#topBar img{
      float: right;
    transform: translate(0, -13px);

}
#overlay table{
  padding-left:5px;
  text-align: left;
}
#overlay .line-marker{
  display:none;
  padding-left: 35px;
  text-align: left;
  font-size: 2em;
}
#overlay .line{
  display:none;
  padding-left: 2px;
  text-align: left;
  font-size: 2em;
}
#overlay .orange{
  color:var(--orange);
}
#overlay .green{
  color:var(--green);
}
#overlay .red{
  color:var(--red);
}
#typewriter{
	display:block;
	text-align: left;
  	font-size: 2em;
        margin-left: 5px;
	margin: 0;
	font-family: "Courier New";

	&:after{
		content: "|";
    font-color: white;
		animation: blink 500ms linear infinite alternate;
	}
}
@-webkit-keyframes blink{
	0%{opacity: 0;}
	100%{opacity: 1;}
}

@-moz-keyframes blink{
	0%{opacity: 0;}
	100%{opacity: 1;}
}

@keyframes blink{
	0%{opacity: 0;}
	100%{opacity: 1;}
}
@keyframes flicker {
  0% {
    opacity: 0.27861;
  }
  5% {
    opacity: 0.34769;
  }
  10% {
    opacity: 0.23604;
  }
  15% {
    opacity: 0.90626;
  }
  20% {
    opacity: 0.18128;
  }
  25% {
    opacity: 0.83891;
  }
  30% {
    opacity: 0.65583;
  }
  35% {
    opacity: 0.67807;
  }
  40% {
    opacity: 0.26559;
  }
  45% {
    opacity: 0.84693;
  }
  50% {
    opacity: 0.96019;
  }
  55% {
    opacity: 0.08594;
  }
  60% {
    opacity: 0.20313;
  }
  65% {
    opacity: 0.71988;
  }
  70% {
    opacity: 0.53455;
  }
  75% {
    opacity: 0.37288;
  }
  80% {
    opacity: 0.71428;
  }
  85% {
    opacity: 0.70419;
  }
  90% {
    opacity: 0.7003;
  }
  95% {
    opacity: 0.36108;
  }
  100% {
    opacity: 0.24387;
  }
}
@keyframes textShadow {
  0% {
    text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), -0.4389924193300864px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  5% {
    text-shadow: 2.7928974010788217px 0 1px rgba(0,30,255,0.5), -2.7928974010788217px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  10% {
    text-shadow: 0.02956275843481219px 0 1px rgba(0,30,255,0.5), -0.02956275843481219px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  15% {
    text-shadow: 0.40218538552878136px 0 1px rgba(0,30,255,0.5), -0.40218538552878136px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  20% {
    text-shadow: 3.4794037899852017px 0 1px rgba(0,30,255,0.5), -3.4794037899852017px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  25% {
    text-shadow: 1.6125630401149584px 0 1px rgba(0,30,255,0.5), -1.6125630401149584px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  30% {
    text-shadow: 0.7015590085143956px 0 1px rgba(0,30,255,0.5), -0.7015590085143956px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  35% {
    text-shadow: 3.896914047650351px 0 1px rgba(0,30,255,0.5), -3.896914047650351px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  40% {
    text-shadow: 3.870905614848819px 0 1px rgba(0,30,255,0.5), -3.870905614848819px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  45% {
    text-shadow: 2.231056963361899px 0 1px rgba(0,30,255,0.5), -2.231056963361899px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  50% {
    text-shadow: 0.08084290417898504px 0 1px rgba(0,30,255,0.5), -0.08084290417898504px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  55% {
    text-shadow: 2.3758461067427543px 0 1px rgba(0,30,255,0.5), -2.3758461067427543px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  60% {
    text-shadow: 2.202193051050636px 0 1px rgba(0,30,255,0.5), -2.202193051050636px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  65% {
    text-shadow: 2.8638780614874975px 0 1px rgba(0,30,255,0.5), -2.8638780614874975px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  70% {
    text-shadow: 0.48874025155497314px 0 1px rgba(0,30,255,0.5), -0.48874025155497314px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  75% {
    text-shadow: 1.8948491305757957px 0 1px rgba(0,30,255,0.5), -1.8948491305757957px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  80% {
    text-shadow: 0.0833037308038857px 0 1px rgba(0,30,255,0.5), -0.0833037308038857px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  85% {
    text-shadow: 0.09769827255241735px 0 1px rgba(0,30,255,0.5), -0.09769827255241735px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  90% {
    text-shadow: 3.443339761481782px 0 1px rgba(0,30,255,0.5), -3.443339761481782px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  95% {
    text-shadow: 2.1841838852799786px 0 1px rgba(0,30,255,0.5), -2.1841838852799786px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  100% {
    text-shadow: 2.6208764473832513px 0 1px rgba(0,30,255,0.5), -2.6208764473832513px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
}
.crt::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}
.crt::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 2;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}
.crt {
  animation: textShadow 1.6s infinite;
}